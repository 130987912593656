import {handleDDLclick} from "./deferred-deep-linking";

/**
 * Document on ready handler
 * Function to execute when the DOM is fully loaded.
 */
function documentReadyHandler() {
  MNTN_PIXEL_INIT();

  document.querySelectorAll('a').forEach( function( link ) {
    if (link.href.includes("internal-dlnk")){
      $(link).click(handleDDLclick.bind(this, link, link.href));
      $(link).attr("href", "");
    }
  })

  // Hides the quizstrip from the current view
  $('#close-quiz-strip').click(function () { $('#widget-quiz-strip').hide() })

  // Gets the quizstrip response - same behavior regardless of response
  document.querySelectorAll('.quiz-strip-response > p').forEach( function(el) {
    $(el).click(() => {
      // Hide the quiz-strip junk
      $('div#quiz-strip-inner').hide()
      // show the button to take to listings
      $('div#result-inner').show()
      // track
      if(analytics&&analytics.track) 
        analytics.track('quiz_strip_step_1', { cta:el.innerText });
    })
  })
  
    // TODO: Send to the listings page

  // intro & testimonials slider init
  $(".flexslider").flexslider({
    animation: "slide",
    controlNav: true
  });

  // show dropdown-menu on hover in main menu
  $("#main_menu .nav-item").each(function(index) {
    var $self = $(this);
    $self.hover(function handlerIn(event) {
      if (window.innerWidth >= 1200) {
        $self.find(".dropdown-toggle").click();
      }
    });
  });

  // parallax
  $(".parallax").paroller({
    factor: 0.3,
    factorXs: 0.1,
    type: "background",
    diretion: "vertical"
  });

  /** mobile footer functionality */
  $('#mobile-footer').on('click', 'a.footer-list-toggle', function(evt) {
    evt.preventDefault();
    let link = $(this);
    let div = link.next('div.footer-list-content');
    link.toggleClass('open');
    div.toggleClass('open');
  });

  /**
   * mobile menu toggle functionality 
   */
  $('#mobile-menu-toggle').click(function(evt) {
    evt.preventDefault();
    $('#mobile-navigation-menu').toggleClass('show');
  });  

  // smoth scroll for anchor links
  new SmoothScroll('a[href*="#"]:not(#totop):not([href="#"]):not(.make-tab):not(.generation-tab)', {
    topOnEmptyHash: false,
    speed: 1000,
    speedAsDuration: true,
    offset: function(anchor, toggle) {
      return window.innerHeight / 20;
    },
    updateURL: false,
    easing: "easeInOutQuint"
  });

  // smooth scroll for to top button
  new SmoothScroll("#totop", {
    topOnEmptyHash: false,
    speed: 1000,
    speedAsDuration: true,
    offset: 0,
    updateURL: false,
    easing: "easeInOutQuint"
  });

  /*
  Price Pulse Banner
  */
  var pp_banner = $("#pp_banner");
  var pp_banner_x = $("#pp_banner_x");
  pp_banner_x.on('click', function(e){
    pp_banner.css('display', 'none');
  });

  // select state/city + search button dynamic behaviors
  var select_state = $("#select-state");
  var select_city_container = $("#select-city-container");
  var select_city = $("#select-city");
  var button_search_for_cars = $("#button-search-for-cars");
  var i, ii;
  //
  var states_and_abbrevs = [
    ["al","Alabama"],
    ["ak","Alaska"],
    ["az","Arizona"],
    ["ar","Arkansas"],
    ["ca","California"],
    ["co","Colorado"],
    ["ct","Connecticut"],
    ["dc","District of Columbia"],
    ["de","Delaware"],
    ["fl","Florida"],
    ["ga","Georgia"],
    ["hi","Hawaii"],
    ["id","Idaho"],
    ["il","Illinois"],
    ["in","Indiana"],
    ["ia","Iowa"],
    ["ks","Kansas"],
    ["ky","Kentucky"],
    ["la","Louisiana"],
    ["me","Maine"],
    ["md","Maryland"],
    ["ma","Massachusetts"],
    ["mi","Michigan"],
    ["mn","Minnesota"],
    ["ms","Mississippi"],
    ["mo","Missouri"],
    ["mt","Montana"],
    ["ne","Nebraska"],
    ["nv","Nevada"],
    ["nh","New Hampshire"],
    ["nj","New Jersey"],
    ["nm","New Mexico"],
    ["ny","New York"],
    ["nc","North Carolina"],
    ["nd","North Dakota"],
    ["oh","Ohio"],
    ["ok","Oklahoma"],
    ["or","Oregon"],
    ["pa","Pennsylvania"],
    ["pr","Puerto Rico"],
    ["ri","Rhode Island"],
    ["sc","South Carolina"],
    ["sd","South Dakota"],
    ["tn","Tennessee"],
    ["tx","Texas"],
    ["ut","Utah"],
    ["vt","Vermont"],
    ["va","Virginia"],
    ["wa","Washington"],
    ["wv","West Virginia"],
    ["wi","Wisconsin"],
    ["wy","Wyoming"],
  ];
  // populate states on-page
  for (i = 0, ii = states_and_abbrevs.length; i < ii; ++i) {
    select_state.append('<option value="'+states_and_abbrevs[i][0]+'">'+states_and_abbrevs[i][1]+'</option>');
  }
  //
  var cities_by_state = {
    "al":["Birmingham","Huntsville","Mobile","Montgomery","Cullman","Daphne","Tuscaloosa","Albertville","Decatur","Pelham"],
    "ak":["Anchorage","Fairbanks","Wasilla"],
    "az":["Phoenix","Mesa","Chandler","Tucson","Scottsdale","Gilbert","Avondale","Peoria","Tempe","Glendale"],
    "ar":["Little Rock","Fayetteville","Bryant","Bentonville","Benton","Fort Smith","North Little Rock","Batesville","Jacksonville","Sherwood"],
    "ca":["Los Angeles","Mission Viejo","Long Beach","Anaheim","Irvine","San Francisco","Berkeley","San Jose","Fremont","Oakland"],
    "co":["Denver","Aurora","Colorado Springs","Fort Collins","Littleton","Centennial","Englewood","Golden","Boulder","Lakewood"],
    "ct":["Bridgeport","New Haven","Hartford","Danbury","Manchester","Middletown","Greenwich","Fairfield","Wilton"],
    "dc":["Washington"],
    "de":["New Castle","Newark","Seaford"],
    "fl":["Miami","Orlando","Tampa","Tallahassee","West Palm Beach","Saint Petersburg","Sarasota","Jacksonville","Cape Coral","Fort Lauderdale"],
    "ga":["Atlanta","Savannah","Augusta","Columbus","Marietta","Duluth","Buford","Lawrenceville","Macon","Roswell"],
    "hi":["Honolulu","Waipahu","Kaneohe","Hilo"],
    "id":["Boise","Kellogg","Nampa","Coeur d'Alene","Idaho Falls","Meridian","Post Falls","Twin Falls"],
    "il":["Chicago","Round Lake Beach","Rockford","Peoria","Naperville","Schaumburg","Downers Grove","Aurora","Morton Grove","Crystal Lake"],
    "in":["Indianapolis","Fort Wayne","South Bend","Lafayette","Plainfield","Mt Vernon","Greenwood","Fishers","Avon","Bloomington"],
    "ia":["Des Moines","Davenport","Cedar Rapids","Ankeny","Sioux City","Clive","Waukee","Urbandale","Iowa City","Cedar Falls"],
    "ks":["Wichita","Leavenworth","Olathe","Merriam","Salina","Overland Park","Mission","Topeka","Louisburg","Kansas City"],
    "ky":["Louisville","Lexington","Frankfort","Paris","Florence","Corbin","Bowling Green","Nicholasville","Somerset","Glasgow"],
    "la":["New Orleans","Baton Rouge","Lafayette","Shreveport","Hammond","Lake Charles","Alexandria","Bossier City","Sulphur","Gonzales"],
    "me":["Bangor","Wiscasset","Westbrook","Sanford","Waterville","South Portland","Portland","Augusta"],
    "md":["Baltimore","Silver Spring","Laurel","Owings Mills","Camp Springs","Gaithersburg","Hurlock","Bethesda","Rockville","College Park"],
    "ma":["Boston","Worcester","Springfield","Braintree","Danvers","Quincy","Brockton","Lynnfield","Natick","Norwood"],
    "mi":["Detroit","Ann Arbor","Flint","Grand Rapids","Lansing","Sterling Heights","Farmington Hills","Dearborn","Taylor","Troy"],
    "mn":["Minneapolis","Saint Paul","Burnsville","Inver Grove Heights","Bloomington","Rochester","St Cloud","Golden Valley","Hopkins","St Paul"],
    "ms":["Jackson","Brandon","Tupelo","Hattiesburg","Columbus","Olive Branch","Ridgeland","Batesville","Vicksburg"],
    "mo":["Saint Louis","Kansas City","Springfield","St. Louis","KCMO","Columbia","Fenton","Ballwin","Ellisville","O'Fallon"],
    "mt":["Great Falls","Billings","Missoula","Bozeman"],
    "ne":["Omaha","Lincoln","Blair","Ralston","Fremont","La Vista","Wahoo","Crete","Lexington"],
    "nv":["Las Vegas","Henderson","Reno","Pahrump","Carson City","Winnemucca"],
    "nh":["Manchester","Nashua","Concord","Portsmouth","Laconia","Epsom","Salem","Bow"],
    "nj":["Jersey City","Newark","Trenton","Ramsey","Paramus","Edison","Freehold","Englewood","Union","Hamilton Township"],
    "nm":["Albuquerque","Edgewood","Rio Rancho","Hobbs"],
    "ny":["Bronx","Brooklyn","Queens","New York","Manhattan","Staten Island","Syracuse","Rochester","Albany","Buffalo"],
    "nc":["Charlotte","Raleigh","Concord","Durham","Winston-Salem","Greensboro","Fayetteville","Asheville","Cary","Kernersville"],
    "nd":["Fargo","Grand Forks","Bismarck","Dickinson","Minot","Bowman"],
    "oh":["Akron","Cleveland","Columbus","Cincinnati","Dayton","Canton","Youngstown","Toledo","Medina","Brunswick"],
    "ok":["Oklahoma City","Tulsa","Broken Arrow","Norman","Midwest City","Pauls Valley","Purcell","Hinton","Owasso","Lawton"],
    "or":["Portland","Salem","Eugene","Wilsonville","Tualatin","Beaverton","Bend","Medford","Hillsboro","Springfield"],
    "pa":["Philadelphia","Allentown","Reading","Lancaster","Pittsburgh","Harrisburg","Scranton","Erie","Langhorne","Conshohocken"],
    "ri":["Providence","East Providence","Warwick","North Smithfield","West Warwick","North Kingstown","East Greenwich","Woonsocket"],
    "pr":["San Juan"],
    "sc":["Greenville","Columbia","Charleston","Myrtle Beach","Lexington","Seneca","North Charleston","Summerville","Hardeeville","Florence"],
    "sd":["Sioux Falls","Rapid City","Mitchell","Worthing","Yankton"],
    "tn":["Nashville","Memphis","Knoxville","Chattanooga","Mt Juliet","Murfreesboro","Franklin","Clarksville","Collierville","Gallatin"],
    "tx":["Dallas","Houston","Fort Worth","Arlington","Plano","Corpus Christi","San Antonio","Austin","Irving","El Paso"],
    "ut":["Salt Lake City","Provo","Ogden","Draper","Sandy","Murray","Bountiful","Lindon","American Fork","Spanish Fork"],
    "vt":["Springfield","South Burlington"],
    "va":["Richmond","Virginia Beach","Alexandria","Vienna","Fredericksburg","Chantilly","Sterling","Manassas","Fairfax","Arlington"],
    "wa":["Seattle","Spokane","Bellevue","Puyallup","Vancouver","Lynnwood","Kirkland","Olympia","Tacoma","Spokane Valley"],
    "wv":["Sutton","St Albans","Jefferson","Beckley","South Charleston","Martinsburg","Parkersburg","Charleston"],
    "wi":["Milwaukee","Madison","Green Bay","Fond du Lac","Waukesha","Appleton","Kenosha","Oak Creek","Racine","Janesville"],
    "wy":["Cheyenne","Casper","Laramie","Gillette","Rock Springs","Sheridan","Green River","Evanston","Riverton","Jackson"]
  };
  // sort alphabetically
  var state_abbrevs_lowercase = Object.keys(cities_by_state);
  for (i = 0, ii = state_abbrevs_lowercase.length; i < ii; i++) {
    cities_by_state[state_abbrevs_lowercase[i]].sort();
  }
  //
  select_state.on('change', event => {
    select_city.empty();
    select_city.append("<option>Select City</option>");
    // always disable button because changing the state always resets the city
    button_search_for_cars[0].setAttribute("disabled","disabled");
    button_search_for_cars[0].classList.add("disabled");
    //
    var cities = cities_by_state[event.target.value];
    if (cities) {
      for (i = 0, ii = cities.length; i < ii; i++) {
        var city_urlsafe = cities[i].toLowerCase().replace(/[^a-z0-9]/g,"-").replace("--","-");
        select_city.append('<option value="'+city_urlsafe+'">'+cities[i]+'</option>');
      }
      select_city[0].removeAttribute("disabled");
      select_city_container[0].classList.remove("disabled");
    } else {
      select_city[0].setAttribute("disabled","disabled");
      select_city_container[0].classList.add("disabled");
    }
  });
  //
  select_city.on('change', event => {
    var state = select_state.val();
    var city = event.target.value;
    if (state && city) {
      button_search_for_cars[0].removeAttribute("disabled");
      button_search_for_cars[0].classList.remove("disabled");
    } else {
      button_search_for_cars[0].setAttribute("disabled","disabled");
      button_search_for_cars[0].classList.add("disabled");
    }
  });
  //
  button_search_for_cars.on('click', event => {
    var state = select_state.val();
    var city = select_city.val();
    if (state && city) {
      // window.location.pathname = "/sitemap/location/"+state+"/"+city;
      window.location.href = "https://www.copilotsearch.com/sitemap/location/"+state+"/"+city;
    }
  });

  // footer links accordions
  $('.links-display-toggle').on('click', event => {
    $(event.currentTarget).parent().toggleClass("collapsed"); // footer
    $(event.currentTarget).parent().find('.links-section').toggleClass('collapsed'); // nav
    event.preventDefault();
  });

}


if(window.location.pathname === '/') {
  // This code loads the IFrame Player API code asynchronously on the homepage
  let tag = document.createElement('script');

  tag.src = "https://www.youtube.com/iframe_api";
  let firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  // This function creates an <iframe> (and YouTube player)
  //    after the API code downloads.
  window.onYouTubeIframeAPIReady = () => {
    const YouTubeCommercialIds = ['AY474MIOxzA','z7xmhg5mAsQ', '7ig8a21GwWo'];

    YouTubeCommercialIds.forEach(videoId => {
      let player = new YT.Player(`yt-commercial-${videoId}`, {
        videoId,
        playerVars: { 
          'playsinline': 1
        },
        events: {
          // 'onReady': onPlayerReady,
          'onStateChange': onYouTubeIFramePlay
        }
      });
    })
  }
  function onYouTubeIFramePlay(event) {
    if (event.data == YT.PlayerState.PLAYING && analytics && analytics.track) {
      analytics.track("yt_commercial_click", {
        'video_title': event.target.videoTitle
      });
    }
  }
}

/**
 * Document on load handler
 * Function to execute when when element and all sub-elements have been completely loaded.
 */

var Y_PX_OFFSET_FOR_STICKY_HEADER = 580;

function windowLoadHandler() {
  $("#preloader").hide();
  const download_copilot_cta = $('#download_copilot_app_cta');
  if (download_copilot_cta.offset()) {
    Y_PX_OFFSET_FOR_STICKY_HEADER = download_copilot_cta.offset().top - 16; // set the offset relative to the componet we want to stick
  }
  else{
    Y_PX_OFFSET_FOR_STICKY_HEADER = -1;
  }

  // NOW that we have Y_PX offset set, it's safe to call the sticky header incase you haven't scrolled and refreshed down the page.
  sticky_cta_header();
}
/**
 * Window scroll handler
 * @param {object} event scroll event
 */
function windowScrollHandler(event) {
  var $toTop = $("#totop");

  if (window.pageYOffset > 0 && !$toTop.hasClass("active")) {
    $toTop.addClass("active");
  }

  if (window.pageYOffset === 0) {
    $toTop.removeClass("active");
  }

  sticky_cta_header();
}

function sticky_cta_header(){
  if (Y_PX_OFFSET_FOR_STICKY_HEADER == -1){
    return;
  }

  var cta_header = $('#download-app-container');
  if (window.scrollY >= Y_PX_OFFSET_FOR_STICKY_HEADER && !cta_header.hasClass('fixed-download-banner')){
    $('#download-app-container').addClass('fixed-download-banner');
    $('#site_intro').addClass('fixed-header-padding');
  }else if (window.scrollY < Y_PX_OFFSET_FOR_STICKY_HEADER && !!cta_header.hasClass('fixed-download-banner')){
    $('#download-app-container').removeClass('fixed-download-banner');
    $('#site_intro').removeClass('fixed-header-padding');
  }
}

const ALLOW_HANDLERS_TIMEOUT = 500;

$(document).ready(function (event) {
  documentReadyHandler();
});
$(window).on("load", function (event) {
  windowLoadHandler();
});

setTimeout(function() {
  $(window).on("scroll", function (event) {
    windowScrollHandler(event);
  });
}, ALLOW_HANDLERS_TIMEOUT);

$(".cta_ga_click_tracker").on("click", function () {
  if (typeof ga === "function") {
    ga('send', {
      hitType: 'event',
      eventCategory: "CTA",
      eventAction: 'click cta',
      eventLabel: "Other page CTA"
    });
  }
});

$(".hpc-link").on("click", function() {
  if (!!analytics && !!analytics.track){
    analytics.track("hidden_profit_homepage_cta_click", {'cta_type': 'hidden profit calculator'})
  }
})

function MNTN_PIXEL_INIT(){
  // Taken from MNTN developer portal.

  var e=null,b="4.0.0",
      n="32317",
      additional="term=value",
      t,r,i;try{t=top.document.referer!==""?encodeURIComponent(top.document.referrer.substring(0,2048)):""}catch(o){t=document.referrer!==null?document.referrer.toString().substring(0,2048):""}try{r=window&&window.top&&document.location&&window.top.location===document.location?document.location:window&&window.top&&window.top.location&&""!==window.top.location?window.top.location:document.location}catch(u){r=document.location}try{i=parent.location.href!==""?encodeURIComponent(parent.location.href.toString().substring(0,2048)):""}catch(a){try{i=r!==null?encodeURIComponent(r.toString().substring(0,2048)):""}catch(f){i=""}}var l,c=document.createElement("script"),h=null,p=document.getElementsByTagName("script"),d=Number(p.length)-1,v=document.getElementsByTagName("script")[d];if(typeof l==="undefined"){l=Math.floor(Math.random()*1e17)}
}
