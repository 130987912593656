export function handleDDLclick(link, href, e) {
  e.preventDefault();
  
  try {
    //Initializing Smart Script arguments
    let mediaSourceVal = 'valet_seo'
    let campaignSrcVal = null
    
    // Split the href as it comes in
    const cleanedUrl = href.split('?')
    const baseUrl = cleanedUrl[0]
    const excessQps = cleanedUrl[1].split('&')

    // Extract pid and c from any hrefs that have them inline
    for (let i = 0; i < excessQps.length; i++) {
      if (excessQps[i].includes('pid=')) {
        mediaSourceVal = excessQps[i].split('=')[1]
      } else if (excessQps[i].includes('c=')) {
        const extractedParam = excessQps[i].split('=')[1]
        if (extractedParam) {
          // clean the string if it has brackets
          const decodedParam = decodeURIComponent(extractedParam)
          campaignSrcVal = decodedParam.replace(/[\[\]']/g,'')
        }
      }
    }

    // If there is a campaign val - apply it
    const campaignValue = campaignSrcVal
      ? {keys:["utm_campaign"],defaultValue:campaignSrcVal}
      : {keys:["utm_campaign"]};
      
    var oneLinkURL = baseUrl;
    var mediaSource = {keys:["utm_source","pid"],defaultValue:mediaSourceVal};
    var campaign = campaignValue
    var channel = {keys:["utm_medium"],defaultValue:"valet_organic"};
    var afSub1 = {keys:["fbclid"]};
    var googleClickIdKey = "af_sub2";
    var ad = {keys:["utm_content"]};
    var custom_ss_ui = {paramKey:"af_ss_ui",defaultValue:"true"};

    //Call the function after embedding the code through a global parameter on the window object called window.AF_SMART_SCRIPT.
    //Onelink URL is generated.
    var AF_SMART_SCRIPT_RESULT = window.AF_SMART_SCRIPT.generateOneLinkURL({
      oneLinkURL: oneLinkURL,
      afParameters: {
          mediaSource: mediaSource,
          campaign: campaign,
          channel: channel,
          afSub1: afSub1,
          googleClickIdKey: googleClickIdKey,
          ad: ad,
          afCustom: [
              custom_ss_ui
          ]
      }
    });

    const app_origin = window.location.origin.replace("www", "app");

    let ddl_link = "/";
    try {
      ddl_link = link.dataset.ddl || "/";
    } catch (err) {
      console.log(
        "Either no ddl link was offered which is okay, or we failed to decode the ddl"
      );
      console.log(err);
    }

    let extra_query_parameters = "";
    try {
      extra_query_parameters = link.dataset.queryparams || "";
    } catch (err) {
      console.log(
        "Either no extra query params were offered which is okay, or we failed to decode the query params"
      );
      console.log(err);
    }

    // ensure the query parameters fit in the url anon_id=abc&{extra_query_params}/#{ddl_link}
    if (
      extra_query_parameters !== "" &&
      !extra_query_parameters.startsWith("&")
    ) {
      extra_query_parameters = "&" + extra_query_parameters;
    }

    let url =
      "copilotsearchurlscheme." + app_origin +  extra_query_parameters + "/#" + ddl_link;

    window.location = AF_SMART_SCRIPT_RESULT.clickURL + "&af_dp=" + encodeURIComponent(url);
  } catch (err) {
    console.log("[Error] - caught and failing gracefully with original href");
    console.error(err);
    window.location = href;
  }
}
